<template>
  <div>
    <router-view
      :projectId="projectId"
      :projectName="projectName"
    />
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  props: {
    projectId: {
      type: Number
    },
    projectName: {
      type: String
    }
  },
  mounted () {
    console.log(this.projectId)
  }
}
</script>
